<template>
  <div class="slice-settings pa-2">
    <v-slider
      v-model="thickness"
      :min="0.1"
      :max="5"
      :step="0.1"
      label="Slice Thickness"
      hide-details
      class="mt-2"
    >
      <template v-slot:prepend>
        <v-icon size="small">mdi-layers</v-icon>
      </template>
    </v-slider>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useSliceRepresentationStore } from '../store/view-configs/slice-representation';

export default defineComponent({
  name: 'SliceRenderingSettings',
  setup() {
    const sliceRepStore = useSliceRepresentationStore();

    const thickness = computed({
      get: () => sliceRepStore.thickness,
      set: (value: number) => sliceRepStore.setThickness(value),
    });

    return {
      thickness,
    };
  },
});
</script> 