import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModelRepresentationStore = defineStore('modelRepresentation', () => {
  const representation = ref<number>(2); // 2 = Surface (default), 1 = Wireframe, 0 = Points

  function toggleRepresentation() {
    // Cycle through: Surface (2) -> Wireframe (1) -> Points (0)
    representation.value = (representation.value + 2) % 3;
  }

  return {
    representation,
    toggleRepresentation,
  };
}); 