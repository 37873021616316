<template>
  <div>
    <v-row no-gutters justify="space-between" align="center" class="mb-1">
      <v-col cols="6">
        <v-checkbox class="ml-3" label="All visible" v-model="allVisible" density="compact" hide-details
          @change="toggleAllVisibility" />
      </v-col>
      <v-col cols="6" align-self="center" class="d-flex justify-end">
        <v-btn icon variant="text" @click="previousModel" class="mr-2">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon variant="text" @click="nextModel">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Animation Models Section -->
    <v-virtual-scroll :items="animationModels" max-height="600" item-height="60">
      <template v-slot:default="{ item: model }">
        <v-list-item :key="model.id" :value="model.id">
          <v-row align="center">
            <v-col cols="6">
              <v-list-item-title>{{ model.metadata.name }}</v-list-item-title>
              <v-list-item-subtitle>
                Steps: {{ model.metadata.animationSteps }}
              </v-list-item-subtitle>
            </v-col>

            <v-col cols="6" class="d-flex justify-end align-center">
              <v-list-item-action start @click="toggleVisibility(model.id)">
                <v-icon>{{ model.metadata.visible ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
              </v-list-item-action>

              <v-menu top nudge-bottom="105" nudge-left="16" :close-on-content-click="false">
                <template v-slot:activator="{ props }">
                  <v-btn v-bind="props" color="grey-lighten-1" icon="mdi-circle" variant="text">
                    <v-icon :color="getColorString(model.metadata.displayColor)"></v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text class="pa-0">
                    <v-color-picker v-model="model.metadata.displayColor" @update:modelValue="updateColor(model.id)"
                      mode="rgba" hide-inputs></v-color-picker>
                  </v-card-text>
                </v-card>
              </v-menu>

              <!-- Animation Controls -->
              <v-btn icon variant="text" @click="playAnimation(model.id)" v-if="!isPlaying[model.id]">
                <v-icon>mdi-play</v-icon>
                <v-tooltip location="top" activator="parent">Play</v-tooltip>
              </v-btn>
              <v-btn icon variant="text" @click="pauseAnimation(model.id)" v-else>
                <v-icon>mdi-pause</v-icon>
                <v-tooltip location="top" activator="parent">Pause</v-tooltip>
              </v-btn>
              <v-btn icon variant="text" @click="resetAnimation(model.id)">
                <v-icon>mdi-restart</v-icon>
                <v-tooltip location="top" activator="parent">Reset</v-tooltip>
              </v-btn>

              <v-btn icon="mdi-delete" variant="text" @click="deleteModel(model.id)">
                <v-icon>mdi-delete</v-icon>
                <v-tooltip location="top" activator="parent">Delete</v-tooltip>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useModelStore } from '../store/datasets-models';

export default defineComponent({
  name: 'AnimationSurfaceModule',
  setup() {
    const modelStore = useModelStore();
    const isPlaying = ref<Record<string, boolean>>({});
    const currentStep = ref<Record<string, number>>({});
    const animationIntervals = ref<Record<string, number>>({});

    const animationModels = computed(() => {
      return modelStore.idList
        .filter(id => modelStore.metadata[id].animationSteps) // Filter for animation models
        .map(id => ({
          id,
          metadata: modelStore.metadata[id],
        }));
    });

    const allVisible = computed(() => {
      return animationModels.value.every(model => model.metadata.visible);
    });

    function toggleAllVisibility() {
      const visible = !allVisible.value;
      animationModels.value.forEach(model => {
        model.metadata.visible = visible;
      });
    }

    function toggleVisibility(id: string) {
      modelStore.metadata[id].visible = !modelStore.metadata[id].visible;
    }

    function updateVisibility() {
      modelStore.idList.forEach(id => {
        modelStore.metadata[id].visible = (id === modelStore.currentId);
      });
    }

    function nextModel() {
      modelStore.incrementIndex();
      updateVisibility();
    }

    function previousModel() {
      modelStore.decrementIndex();
      updateVisibility();
    }

    function updateColor(id: string) {
      modelStore.refreshDisplay();
    }

    function deleteModel(id: string) {
      pauseAnimation(id);
      modelStore.deleteData(id);
    }

    function playAnimation(id: string) {
      if (!currentStep.value[id]) {
        currentStep.value[id] = 0;
      }

      isPlaying.value[id] = true;
      const polydata = modelStore.dataIndex[id];
      const totalSteps = modelStore.metadata[id].animationSteps!;

      animationIntervals.value[id] = window.setInterval(() => {
        if (currentStep.value[id] >= totalSteps) {
          currentStep.value[id] = 0;
        }

        const points = polydata.getPoints();
        const animationArray = polydata.getPointData().getArrayByName(`animation_${currentStep.value[id]}`);

        if (points && animationArray) {
          const numPoints = points.getNumberOfPoints();
          for (let i = 0; i < numPoints; i++) {
            const animationValue = animationArray.getTuple(i);
            points.setPoint(i, animationValue[0], animationValue[1], animationValue[2]);
          }
          polydata.modified();
          modelStore.refreshDisplay();
        }

        currentStep.value[id]++;
      }, 20); // 20ms interval for smooth animation
    }

    function pauseAnimation(id: string) {
      if (animationIntervals.value[id]) {
        clearInterval(animationIntervals.value[id]);
        delete animationIntervals.value[id];
      }
      isPlaying.value[id] = false;
    }

    function resetAnimation(id: string) {
      pauseAnimation(id);
      currentStep.value[id] = 0;
      
      const polydata = modelStore.dataIndex[id];
      const points = polydata.getPoints();
      const animationArray = polydata.getPointData().getArrayByName('animation_0');

      if (points && animationArray) {
        const numPoints = points.getNumberOfPoints();
        for (let i = 0; i < numPoints; i++) {
          const animationValue = animationArray.getTuple(i);
          points.setPoint(i, animationValue[0], animationValue[1], animationValue[2]);
        }
        polydata.modified();
        modelStore.refreshDisplay();
      }
    }

    function getColorString(color: { r: number; g: number; b: number; a: number; }) {
      return `rgb(${color.r}, ${color.g}, ${color.b})`;
    }

    return {
      animationModels,
      allVisible,
      isPlaying,
      toggleAllVisibility,
      toggleVisibility,
      nextModel,
      previousModel,
      updateColor,
      deleteModel,
      playAnimation,
      pauseAnimation,
      resetAnimation,
      getColorString,
    };
  },
});
</script>

<style scoped>
.datasetPanel:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
</style> 