import { defineStore } from 'pinia';
import { ref } from 'vue';

export const DEFAULT_SLICE_THICKNESS = 1;

export const useSliceRepresentationStore = defineStore('sliceRepresentation', () => {
  const thickness = ref<number>(DEFAULT_SLICE_THICKNESS);

  function setThickness(value: number) {
    thickness.value = value;
  }

  return {
    thickness,
    setThickness,
  };
}); 