<template>
  <div class="vtk-container-wrapper">
    <!-- Info icon with tooltip -->
    <div class="top-right-info">
      <v-menu open-on-hover location="bottom left" dark max-width="350px">
        <template v-slot:activator="{ props }">
          <v-icon
            v-bind="props"
            dark
            size="x-large"
            class="pointer-events-all hover-info"
          >
            mdi-information
          </v-icon>
        </template>
        <v-list class="bg-grey-darken-3">
          <v-list-item>
            <v-list-item-title class="font-weight-bold">
              {{ modelMetadata?.name }}
            </v-list-item-title>

            <v-divider />

            <!-- Displaying individual measurements -->
            <v-list-item v-for="(measurement, index) in measurements" :key="index">
              <v-list-item-title class="font-weight-bold">
                {{ measurement.name }}
              </v-list-item-title>
              <v-list-item-subtitle>{{ measurement.type }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{ measurement.value }}</v-list-item-subtitle>
            </v-list-item>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- VTK Container -->
    <div class="vtk-container" ref="vtkContainerRef"></div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, onBeforeUnmount, defineComponent, PropType, watch, computed } from 'vue';
import vtkActor from '@kitware/vtk.js/Rendering/Core/Actor';
import vtkMapper from '@kitware/vtk.js/Rendering/Core/Mapper';
import { useModelStore } from '@/src/store/datasets-models';
import { storeToRefs } from 'pinia';
import { useSharedCameraStore } from '@/src/store/shared-camera';
import vtkRenderWindow from '@kitware/vtk.js/Rendering/Core/RenderWindow';
import { useGroupStore } from '../store/datasets-groups';
import { useViewProxy } from '../composables/useViewProxy';
import { ViewProxyType } from '../core/proxies';
import { useResizeObserver } from '../composables/useResizeObserver';
import vtkLPSView3DProxy from '../vtk/LPSView3DProxy';
import vtkInteractorStyleManipulator from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator';
import InteractionPresets from '@kitware/vtk.js/Interaction/Style/InteractorStyleManipulator/Presets';

export default defineComponent({
  name: 'ModelRenderer',
  props: {
    modelId: {
      type: String as PropType<string>,
      required: true,
    },
    mainRenderWindow: {
      type: Object,
      required: true,
    },
    mainRenderWindowView: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const vtkContainerRef = ref<HTMLElement | null>(null);
    const sharedCameraStore = useSharedCameraStore();
    const modelStore = useModelStore();
    const groupStore = useGroupStore();
    const { sharedCamera, updated } = storeToRefs(sharedCameraStore);

    // Get metadata of the current model
    const modelMetadata = computed(() => modelStore.metadata[props.modelId]);

    // Use group store to extract measurements for the group associated with the modelId
    const measurements = computed(() => {
      return groupStore.getMeasurementsForGroup(props.modelId);
    });

    // Setup view proxy using the composable
    const { viewProxy, setContainer: setViewProxyContainer } = useViewProxy<vtkLPSView3DProxy>(props.modelId, ViewProxyType.Volume);

    watch(
      [sharedCamera, updated],
      ([camera]) => {
        if (viewProxy.value && camera) {
          viewProxy.value.getRenderer().setActiveCamera(camera);
          viewProxy.value.renderLater();
        }
      },
      { immediate: true }
    );

    const syncCamera = () => {
      if (viewProxy.value) {
        const activeCamera = viewProxy.value.getRenderer().getActiveCameraAndResetIfCreated();
        sharedCameraStore.updateSharedCamera(activeCamera);
      }
    };

    // Use resize observer
    useResizeObserver(vtkContainerRef, () => viewProxy.value.resize());

    onMounted(() => {
      if (vtkContainerRef.value) {
        vtkContainerRef.value.id = `renderer-${props.modelId}`;

        const polyData = modelStore.dataIndex[props.modelId];
        if (!polyData) {
          console.error(`Model with id ${props.modelId} not found`);
          return;
        }

        // Setup the view proxy
        viewProxy.value.setOrientationAxesVisibility(false);
        viewProxy.value.setBackground([0.176, 0.137, 0.333]); // rgb(45, 35, 85)
        setViewProxyContainer(vtkContainerRef.value);

        props.mainRenderWindow.addRenderWindow(viewProxy.value.getRenderWindow());
        const renderWindowView = props.mainRenderWindowView.addMissingNode(viewProxy.value.getRenderWindow());
        viewProxy.value.getRenderWindow().addView(renderWindowView);

        // Add main model
        const mapper = vtkMapper.newInstance();
        mapper.setInputData(polyData);
        mapper.setScalarVisibility(true);
        
        const actor = vtkActor.newInstance();
        actor.getProperty().setOpacity(0.8);
        actor.setMapper(mapper);
        viewProxy.value.getRenderer().addActor(actor);

        // Add group actors
        const groupActors = groupStore.getActorsForGroup(props.modelId);
        groupActors.forEach((groupActor) => {
          viewProxy.value.getRenderer().addActor(groupActor);
        });

        // Add submodels if they exist
        const subModels = groupStore.getSubModelsForGroup(props.modelId);
        subModels.forEach((subModelData) => {
          const subMapper = vtkMapper.newInstance();
          subMapper.setInputData(subModelData);

          const subActor = vtkActor.newInstance();
          subActor.setMapper(subMapper);
          subMapper.setScalarVisibility(true);
          viewProxy.value.getRenderer().addActor(subActor);
        });

        viewProxy.value.resetCamera();

        // Setup camera sync
        viewProxy.value.getInteractor().onEndMouseMove(syncCamera);
        viewProxy.value.getInteractor().onEndMouseWheel(syncCamera);

        // Setup interactor style
        const interactorStyle = vtkInteractorStyleManipulator.newInstance();
        const interactorStyleDefinitions = [
          { type: 'pan', options: { button: 3 } }, // Pan on Right button drag
          { type: 'pan', options: { button: 1, shift: true } }, // Pan on Shift + Left button drag
          { type: 'zoom', options: { button: 1, control: true } }, // Zoom on Ctrl + Left button drag
          { type: 'zoom', options: { dragEnabled: false, scrollEnabled: true } }, // Zoom on scroll
          { type: 'rotate', options: { button: 1 } } // Rotate on Left button drag
        ];
        InteractionPresets.applyDefinitions(interactorStyleDefinitions, interactorStyle);
        viewProxy.value.getInteractor().setInteractorStyle(interactorStyle);

        onBeforeUnmount(() => {
          setViewProxyContainer(null);
          viewProxy.value.setContainer(null);
          actor.delete();
          mapper.delete();
        });
      }
    });

    return {
      vtkContainerRef,
      modelMetadata,
      measurements,
    };
  },
});
</script>

<style scoped>
.vtk-container-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.vtk-container {
  width: 100%;
  height: 100%;
  position: relative;
  flex: 1;
}

.top-right-info {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}

.hover-info {
  cursor: pointer;
}
</style>
